'use client';

import clsx from 'clsx';
import { useSession } from 'next-auth/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useAddFavoriteMutation,
  useGetFavoritesQuery,
  useRemoveFavoriteMutation
} from '@akinon/next/data/client/wishlist';
import { Product } from '@akinon/next/types';
import { Icon } from '@theme/components';
import { useRouter } from '@akinon/next/hooks';
import { ROUTES } from '@theme/routes';
import { pushAddToWishlist } from 'utils/gtm';

interface FavButtonProps {
  label?: string;
  size?: number;
  className?: string;
}

const useFavButton = (productPk: number, product?: Product) => {
  const { status } = useSession();

  const { data: favorites } = useGetFavoritesQuery(
    {
      limit: 1000
    },
    { skip: status !== 'authenticated' }
  );

  const favoriteItem = useMemo(
    () =>
      favorites?.results?.find(
        (item) => Number(item.product.pk) === Number(productPk)
      ),
    [favorites, productPk]
  );

  const [isActive, setIsActive] = useState(Boolean(favoriteItem));

  const [addFavorite] = useAddFavoriteMutation();
  const [removeFavorite] = useRemoveFavoriteMutation();
  const router = useRouter();

  const handleClick = useCallback(() => {
    status !== 'authenticated'
      ? router.push(ROUTES.AUTH)
      : favoriteItem
        ? removeFavorite(favoriteItem.pk)
        : (
          addFavorite(productPk),
          pushAddToWishlist(product)
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favoriteItem, productPk, addFavorite, removeFavorite]);

  useEffect(() => {
    setIsActive(Boolean(favoriteItem));
  }, [favoriteItem]);

  const FavButton = useMemo(() => {
    const View = (props: FavButtonProps) => (
      <button
        className={clsx(
          'flex items-center hover:cursor-pointer hover:text-secondary-hover',
          props.className
        )}
        onClick={handleClick}
        data-testid="favourites-icon"
      >
        <Icon
          name={isActive ? 'heart-full' : 'heart-stroke'}
          className={clsx('text-2xl hover:fill-secondary-hover md:text-3xl')}
        />
        {props.label && <span className="ml-2">{props.label}</span>}
      </button>
    );

    return View;
  }, [isActive, handleClick]);

  return {
    FavButton
  };
};

export default useFavButton;
